<template>
  <div id="tiflux--chat">
    <div class="chat__form">
      <iframe
        border="0"
        :src="chatFrameUrl()"
        style="
          height: 100%;
          width: 100%;
          max-width: 100%;
          background: #fff;
          border: 0px;
          -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
          border: 1px solid rgba(0, 0, 0, 0.06);
          border-radius: 10px;
        "
      />
    </div>
  </div>
</template>

<script>
import { getAppVersions } from '@/utils/app-utils'
import { mapState } from 'vuex'
import isElectron from 'is-electron'
import { getUserData } from '@/auth/utils'

// https://public-assets.tiflux.com/chat_widget.js
export default {
  components: {},
  data() {
    return {
      params: {
        organization_token: '2bddc4050c717a23feadff7072845fa43dfb251c',
        icon_color: '#008200',
        init_minimal: true,
        show_chatIcon: true,
        autoSend: true,
      },
    }
  },
  computed: {
    ...mapState('pages/pdv/payBoxConfiguration', ['payBox']),
  },
  mounted() {
    // window.organization_token = '2bddc4050c717a23feadff7072845fa43dfb251c'
    // window.icon_color = '#008200'
    // window.init_minimal = true
    // window.show_chatIcon = true
    // window.name = this.$store.state.auth.userData.fullName
    // window.mail = this.$store.state.auth.userData.email
    // window.autoSend = true
    // // eslint-disable-next-line no-unused-vars, no-var, vars-on-top
    // var script = document.createElement('script')
    // script.type = 'text/javascript'
    // script.src = `https://public-assets.tiflux.com/chat_widget.js?organization_token=${this.organization_token}`
    // document.head.appendChild(script)
  },
  methods: {
    chatFrameUrl() {
      return `https://widget.tiflux.com/chat?${this.buildUrlParams()}` // precisamos do /chat, pq sem ele o Tiflux redireciona e perde alguns query params, como o equip_id que forçamos não mostrar o X para fechar
    },
    buildUrlParams() {
      const appVersion = JSON.stringify(getAppVersions())

      const userData = getUserData()

      const environment = window.env || 'local'
      const extraParams = {
        domain: window.location.hostname,
        appVersion,
        environment,
        userName: userData.userName,
        email: userData.email,
      }

      let equipId = 9999 // fake id, tiflux test > 0
      if (isElectron()) {
        const { id, number, description, preferredInvoiceType, tef, store } = this.payBox

        if (id > 0) equipId = id

        extraParams.payBox = JSON.stringify({
          id,
          number,
          description,
          preferredInvoiceType,
        })

        extraParams.store = JSON.stringify({
          id: store?.id,
          name: store?.name,
          document: store?.document,
          type: store?.type,
        })

        extraParams.tef = JSON.stringify({
          enabled: tef?.enabled,
          pinpadModel: tef?.pinpadModel,
          restartClientMode: tef?.restartClientMode,
          warmClientMode: tef?.warmClientMode,
          restartDevice: tef?.restartDevice,
        })
      }

      const urlParams = {
        ...this.params,
        name: userData.fullName,
        mail: userData.email,
        phone: '',
        equip_id: equipId,
      }

      const result = Object.keys(urlParams)
        .map(key => `${key}=${encodeURIComponent(urlParams[key])}`)
        .concat(`extra_params=${encodeURI(JSON.stringify(JSON.stringify(extraParams)))}`)
        .join('&')

      return result
    },
  },
}
</script>

<style lang="css" scoped>
#tiflux--chat {
  top: 6rem !important;
}

#tiflux--chat header {
  right: 430px !important;
  bottom: 30px !important;
}
#click_exit {
  display: none !important;
}
#tiflux--chat .chat__close {
  display: none !important;
}
.display-none {
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#tiflux--chat header:hover svg {
  transform: scale(1.1);
}
#tiflux--chat {
  color: #9a9a9a;
  font: 100%/1.5em Arial, sans-serif;
  margin: 0;
}
#tiflux--chat h4,
#tiflux.chat-h5,
.tiflux--chat input {
  font-family: Arial, sans-serif;
  margin: 0;
}
#tiflux--chat a {
  text-decoration: none;
}
.chatform {
  background: #fff;
}
.chatform fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
#tiflux--chat h4,
#tiflux.chat-h5 {
  line-height: 1.5em;
}
#tiflux--chat hr {
  background: #e9e9e9;
  border: 0;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 1px;
  margin: 0;
  min-height: 1px;
}
.tiflux--chat input {
  border: 0;
  color: #444;
  font-size: 100%;
  line-height: normal;
}
#tiflux--chat,
#tiflux--chat h4 {
  font-size: 12px;
}
.chatclearfix:before,
.clearfix:after {
  content: '';
  display: table;
}
.chatclearfix:after {
  clear: both;
}
#tiflux--chat {
  z-index: 999999;
  bottom: 95px;
  right: 28px;
  position: fixed;
}
#tiflux--chat header {
  top: auto;
  left: auto;
  background: #31a4dd;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
  width: 60px;
  height: 60px;
  border-radius: 70px;
  color: #fff;
  cursor: pointer;
  padding: 11px 11px;
  position: fixed;
  right: 22px;
  bottom: 15px;
}
#tiflux--chat h4:before {
  background: #44bd32;
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 8px;
  margin: 0 8px 0 0;
  width: 8px;
}
#tiflux--chat h5 {
  font-size: 10px;
}
#tiflux--chat form {
  padding: 24px;
}
#tiflux--chat input[type='text'],
#tiflux--chat input[type='email'] {
  border: 1px solid #ccc;
  border-radius: 3px;
  display: block;
  padding: 8px;
  outline: 0;
  width: 234px;
}
#tiflux--chat form label {
  margin-left: 20px;
  margin-top: 20px;
  display: block;
}
#tiflux--chat .chat__close {
  background: #1b2126;
  border-radius: 50%;
  color: #fff;
  display: block;
  float: right;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  margin: 2px 0 0;
  text-align: center;
  width: 16px;
}
.chat__form {
  display: block;
  width: 370px;
  height: 450px;
  max-height: 700px;
  overflow: initial !important;
}
@media (max-height: 480px) {
  .chat__form {
    max-height: 350px;
  }
}

@media (max-width: 800px) {
  #tiflux--chat iframe {
    max-width: 80%;
  }
  div#tiflux--chat > .chat__form {
    width: auto;
  }
}
</style>
