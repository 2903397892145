<template>
  <li class="nav-item pr-1 pl-1">
    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link"
      @click="togleChat"
    >
      <feather-icon
        ref="actions"
        v-tooltip="{
          content: $t('Fechar o chat (conversa continua ativa)'),
          trigger: 'manual',
          classes: 'fab-tooltip',
          placement: 'left',
        }"
        :class="{ hidden: !showChat }"
        icon="XIcon"
        size="21"
      />
      <feather-icon
        :class="{ hidden: showChat }"
        icon="MessageCircleIcon"
        size="21"
        :title="$t('Iniciar chat de atendimento do suporte')"
      />
    </a>
    <chat-tool-tiflux
      v-if="chatOpened"
      :class="{ hidden: !showChat }"
    />
  </li>
</template>

<script>
import { VTooltip } from 'v-tooltip'
import ChatToolTiflux from './ChatToolTiflux.vue'

export default {
  directives: { tooltip: VTooltip },
  components: { ChatToolTiflux },
  data() {
    return {
      showChat: false,
      chatOpened: false,
    }
  },
  methods: {
    togleChat() {
      this.showChat = !this.showChat
      if (!this.chatOpened) {
        this.chatOpened = true
      }

      if (this.$refs.actions) {
        // eslint-disable-next-line no-underscore-dangle
        const tooltip = this.$refs.actions._tooltip
        if (tooltip) {
          if (this.showChat) {
            tooltip.show()
          } else {
            tooltip.hide()
          }
        }
      }
    },
  },
}
</script>
<style>
.fab-tooltip.tooltip {
  display: block !important;
  padding: 4px;
  z-index: 10000;
}

.fab-tooltip.tooltip .tooltip-inner {
  background: #333333;
  color: white;
  border-radius: 0px;
  padding: 5px 10px 4px;
}

.fab-tooltip.tooltip tooltip-arrow {
  display: none;
}

.fab-tooltip.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.fab-tooltip.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>

<style lang="scss" scoped></style>
