var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "nav-item pr-1 pl-1" },
    [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: { href: "javascript:void(0)" },
          on: { click: _vm.togleChat },
        },
        [
          _c("feather-icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  content: _vm.$t("Fechar o chat (conversa continua ativa)"),
                  trigger: "manual",
                  classes: "fab-tooltip",
                  placement: "left",
                },
                expression:
                  "{\n        content: $t('Fechar o chat (conversa continua ativa)'),\n        trigger: 'manual',\n        classes: 'fab-tooltip',\n        placement: 'left',\n      }",
              },
            ],
            ref: "actions",
            class: { hidden: !_vm.showChat },
            attrs: { icon: "XIcon", size: "21" },
          }),
          _c("feather-icon", {
            class: { hidden: _vm.showChat },
            attrs: {
              icon: "MessageCircleIcon",
              size: "21",
              title: _vm.$t("Iniciar chat de atendimento do suporte"),
            },
          }),
        ],
        1
      ),
      _vm.chatOpened
        ? _c("chat-tool-tiflux", { class: { hidden: !_vm.showChat } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }