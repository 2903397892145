var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "tiflux--chat" } }, [
    _c("div", { staticClass: "chat__form" }, [
      _c("iframe", {
        staticStyle: {
          height: "100%",
          width: "100%",
          "max-width": "100%",
          background: "#fff",
          border: "1px solid rgba(0, 0, 0, 0.06)",
          "-webkit-box-shadow":
            "0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16)",
          "box-shadow":
            "0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16)",
          "border-radius": "10px",
        },
        attrs: { border: "0", src: _vm.chatFrameUrl() },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }