import { render, staticRenderFns } from "./ChatToolTiflux.vue?vue&type=template&id=46205238&scoped=true&"
import script from "./ChatToolTiflux.vue?vue&type=script&lang=js&"
export * from "./ChatToolTiflux.vue?vue&type=script&lang=js&"
import style0 from "./ChatToolTiflux.vue?vue&type=style&index=0&id=46205238&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46205238",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/echope/echope-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('46205238')) {
      api.createRecord('46205238', component.options)
    } else {
      api.reload('46205238', component.options)
    }
    module.hot.accept("./ChatToolTiflux.vue?vue&type=template&id=46205238&scoped=true&", function () {
      api.rerender('46205238', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/components/ChatToolTiflux.vue"
export default component.exports